
.coin-block {
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .score-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    img {
      width: 42px;
      height: 42px;
    }
    span {
      color: #fff;
      font-size: 40px;
      font-weight: 500;
    }
  }
  .score-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .information {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #feda2c;
        padding: 2px 5px;
        font-size: 10px;
        border-radius: 12px;
        overflow: hidden;
      }
      .text {
        font-size: 12px;
        display: flex;
        color: #fff;
      }
    }
  }
}
.tap-block {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .round {
    width: 322px;
    height: 322px;
    background-image: url("../img/round.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}