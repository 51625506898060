.panel-friends{
  overflow-x: hidden;
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  &__content{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title{
      text-align: center;
      font-size: 30px;
    }
    .subtitle{
      text-align: center;
      font-size: 17px;
      font-weight: 300;
      opacity: .6;
    }
  }
}