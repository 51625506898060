
* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #2C2F35;
}

html {
  overflow-x: hidden;
  height: 100%;
  background-color: #2C2F35;
}

.panel{
  overflow-x: hidden !important;
  width: 100vw;
  padding: 20px 30px;
  color: white;
}

.navheader {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 0 15px;

  .user-info {
    width: 35%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .avatar {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      overflow: hidden;
      border: solid 2px #fff;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .username {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .logobox {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .navtools {
    width: 35%;
  }
}

.loading {
  color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-weight: 700;
  }

  justify-content: center;
  align-items: center;
}

.animated-click {
  font-weight: 700;
  color: white;
  z-index: 5;
  user-select: none;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  aspect-ratio: 1;
  background: #25b09b;
  box-shadow: 0 0 60px 15px #25b09b;
  transform: translate(-80px);
  clip-path: inset(0);
  animation: l4-1 0.5s ease-in-out infinite alternate,
  l4-2 1s ease-in-out infinite;
}

@keyframes l4-1 {
  100% {
    transform: translateX(80px)
  }
}

@keyframes l4-2 {
  33% {
    clip-path: inset(0 0 0 -100px)
  }
  50% {
    clip-path: inset(0 0 0 0)
  }
  83% {
    clip-path: inset(0 -100px 0 0)
  }
}

.error {
  width: 100vw;
  height: 90vh;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  &__title {
    text-align: center;
    color: #cc0033;
  }
}

.btn {
  outline: none;
  background-color: white;
  font-weight: 600;
  border: none;
  transition: all .2s;
  &-w100{
    width: 100%;
  }
  &-success {
    background-color: rgba(60, 182, 74, 0.5);
  }
  &:active{
    transform: scale(.95);
    opacity: .6;
  }
}
.friends-list{
  display: flex;
  flex-direction: column;
  gap: 15px;
  &__list{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.user-rating{
  width: 100%;
  border-radius: 16px;
  padding: 10px 10px;
  &__main{
    display: flex;
    flex-direction: row;
    gap: 15px;
    img{
      border-radius: 100%;
      width: 50px;
    }
  }
}