.nav-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 110px;
  padding: 15px;

  ul.menus {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff1f;
    border: solid 1px #ffffff2d;
    border-radius: 18px;
    padding: 5px;
    justify-content:space-between;
    margin: 0;
    box-shadow: 0px 4px 12px #00000026;
    li {
      flex: 1;
      height: 100%;
      list-style: none;
      margin: 0;
      padding: 0 15px;
      border-radius: 16px;
      display: flex;
      a {
        cursor: pointer;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        text-transform: none;
        padding: 6px 0;
        gap: 4px;
        img {
          width: 18px;
          height: 18px;
          object-fit: cover;
        }
        span {
          font-size: 12px;
          color: #fff;

        }
      }
      &.active {
        background-color: #ffffff2f;
      }
    }
  }
}